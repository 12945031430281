body {
  margin: 0;
  background-color: #1B1D1A;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'PPObjectSans-Regular','PPObjectSans-Slanted','PPObjectSans-Heavy','PPObjectSans-HeavySlanted';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: PPObjectSans-Regular;
  src: url("./Fonts/PPObjectSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: PPObjectSans-Slanted;
  src: url("./Fonts/PPObjectSans-Slanted.otf") format("opentype");
}

@font-face {
  font-family: PPObjectSans-Heavy;
  src: url("./Fonts/PPObjectSans-Heavy.otf") format("opentype");
}

@font-face {
  font-family: PPObjectSans-HeavySlanted;
  src: url("./Fonts/PPObjectSans-HeavySlanted.otf") format("opentype");
}

::-webkit-scrollbar{
  width:1px;
  height:1px;
  background: #1B1D1A;
}

::-webkit-scrollbar-thumb{  
  border-radius: 15px;
  background: #2A880A;
}