.SlideImg {
    object-fit: cover;
    width: 80vw;
    height: 30vw;
    border-radius: 1.3vw;
    margin-right: 1.5vw;
    overflow: hidden;
}

/* Style for the dots */
.slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

/* Style for inactive dot */
.slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: none;
    background-color: #e8e8e8;
    cursor: pointer;
    outline: none;
}

/* Style for active dot */
.slick-dots li.slick-active button {
    background-color: #2a880a;
}
